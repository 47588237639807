const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    CREATE_MAP_SET_BEGIN: true,
    CREATE_MAP_SET_SUCCESS: true,
    CREATE_MAP_SET_ERROR: true,
    LOAD_MAP_SETS_BEGIN: true,
    LOAD_MAP_SETS_SUCCESS: true,
    LOAD_MAP_SETS_ERROR: true,
    LOAD_ORG_MAP_SETS_BEGIN: true,
    LOAD_ORG_MAP_SETS_SUCCESS: true,
    LOAD_ORG_MAP_SETS_ERROR: true,
    UPDATE_MAP_SET_BEGIN: true,
    UPDATE_MAP_SET_SUCCESS: true,
    UPDATE_MAP_SET_ERROR: true,
    DELETE_MAP_SET_BEGIN: true,
    DELETE_MAP_SET_SUCCESS: true,
    DELETE_MAP_SET_ERROR: true,
    LOAD_MAP_SET_BEGIN: true,
    LOAD_MAP_SET_SUCCESS: true,
    LOAD_MAP_SET_ERROR: true,
    // Multi Map
    RESET_ACTIVE_MULTI_MAP: true,
    CREATE_MULTI_MAP_BEGIN: true,
    CREATE_MULTI_MAP_SUCCESS: true,
    CREATE_MULTI_MAP_ERROR: true,
    UPDATE_MULTI_MAP_BEGIN: true,
    UPDATE_MULTI_MAP_ERROR: true,
    UPDATE_MULTI_MAP_SUCCESS: true,
    DELETE_MULTI_MAP_BEGIN: true,
    DELETE_MULTI_MAP_SUCCESS: true,
    DELETE_MULTI_MAP_ERROR: true,

});
